import React, { useEffect, useState } from 'react';
import { GetOIDCHub } from "../../assets/auth/serviceConfig";
import { OidcUser } from "@ftdr/pkce-js";
import { SearchFilters, SearchResults } from './';
import { SearchFilter } from '../../models';

export const SearchView = () => {
  const [filters, setFilters] = useState<SearchFilter|undefined>(undefined); 
  const corpClient = GetOIDCHub().serviceMap.get("corporate")
  const [usr, setUser] = useState<OidcUser>();

  useEffect(() => {
    if (corpClient && !usr) {
      corpClient.renewToken().catch(() => {
        window.location.replace("/logout");
      })
      corpClient.getUser().then(u => {
          if (u?.id_token) {
              setUser(u);
          }
          else if(!usr){
            window.location.replace("/login");
          }
      })
    }
  });

  return (
    <div className="verint-search-container">
        <SearchFilters 
          user={usr} 
          onClearFilers={() => {
            setFilters(undefined);
          }}
          filtersApplied={(filters: SearchFilter)=>{
            setFilters(filters);
          }}></SearchFilters>
        <SearchResults filters={filters} user={usr}></SearchResults>
    </div>
  );
}