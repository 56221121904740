import React, { useEffect } from 'react';
import { GetOIDCHub } from "../../assets/auth/serviceConfig";


export const Logout = () => {
  const corpClient = GetOIDCHub().serviceMap.get("corporate")

  useEffect(() => {
    if (corpClient) {
        corpClient.logout().then(u => {
            let key = getQueryVariable('callbackToken');
            if(key) {
                window.location.replace(`/login?callbackToken=${key}`);
            }
            else {
                window.location.replace("/login")
            }
        })
    }
  });
    const getQueryVariable = function(variable:string)
    {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){
                    return pair[1];
                }
            }
            return(false);
}

  return (<div>
      Renewing auth...
  </div>);
}