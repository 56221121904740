import { verintpb, common } from "@ftdr/verint-js-client";
import { AxiosResponse } from "axios";

export class VerintJSClientMock{

    agents = ['John Doe', 'Henry Smith', 'Peter Parker', 'Hannah Gabrielle', 'Jane Doe'];
    phones = ['(650) 513-0514', '(312) 814-1233', '(144) 214-0202', '(450) 815-3334', '(450) 814-1235', '(450) 812-0035'];
    organizations = ['AHS', 'HSA', 'FrontDoor'];
    postScheduleArchiveDownload (requestData: verintpb.IArchiveDownloadRequest, retryCount?: number, backoff?: number): Promise<AxiosResponse<verintpb.IArchiveDownloadResponse>> {
        return  new Promise<AxiosResponse<verintpb.IArchiveDownloadResponse>>((resolve, reject) => {
            const response: AxiosResponse<verintpb.IArchiveDownloadResponse> = {
                data: {
                    status: common.Status.SUCCESS,
                    results: this.generateDownloadResponse(requestData),
                },
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
                request: {}
            }
            setTimeout(function() {
                resolve(response);
            }, 2000);
        });
    }

    getSearchArchive (requestData: verintpb.ISearchRequest, retryCount?: number, backoff?: number): Promise<AxiosResponse<verintpb.ISearchResponse>> {
        let items = this.generateSearchResponse(50);
        if(requestData.queryByAgentName){
            items = items.filter(x => x.agentName && x.agentName?.toLowerCase().includes(requestData.queryByAgentName?.toLowerCase() || ""));
        }
        if(requestData.queryByCustomerPhoneNumber){
            items = items.filter(x => x.phoneNumber && x.phoneNumber?.toLowerCase().includes(requestData.queryByCustomerPhoneNumber?.toLowerCase() || ""));
        }
        return  new Promise<AxiosResponse<verintpb.ISearchResponse>>((resolve, reject) => {
            const response: AxiosResponse<verintpb.ISearchResponse> = {
                data: {
                    status: common.Status.SUCCESS,
                    results: items,
                    totalResults: items.length
                },
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
                request: {}
            }
            resolve(response);
        });
    }

    postVerifyArchiveDownload (requestData: verintpb.IMediaDownloadVerificationRequest): Promise<AxiosResponse<verintpb.IMediaDownloadVerificationResponse>> {
        return  new Promise<AxiosResponse<verintpb.IMediaDownloadVerificationResponse>>((resolve, reject) => {
            const response: AxiosResponse<verintpb.IMediaDownloadVerificationResponse> = {
                data: {
                    status: common.Status.SUCCESS,
                    downloadLink: 'http://www.google.com'
                },
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
                request: {}
            }
            resolve(response);
        });
    }
    
    generateDownloadResponse(requestData: verintpb.IArchiveDownloadRequest): verintpb.IDownloadResponseEntry[] {
        let items: verintpb.IDownloadResponseEntry[] = []
        if(requestData && requestData.downloadRequests){
            for(let i =0 ; i < requestData.downloadRequests?.length; i++) {
                items.push({
                    indexId: requestData.downloadRequests[i].indexId,
                    status: 'Success'
                })
            }
        }
        return items;
    }

    generateSearchResponse(many: number): verintpb.ISearchResult[] {
        let items: verintpb.ISearchResult[] = [];
        for(let i = 0 ; i < many ; i++) {
            items.push({
                indexId: i+'',
                agentName: this.agents[i%5],
                organization: this.organizations[i%3],
                phoneNumber: this.phones[i%6],
                description: `This is a record for ${this.agents[i%5]}`,
                fileSize: 2048,
                fileExtension: 'wav',
                fileType: 'audio',
                fileName: `Call_${i}`,
                startDate: '20200101',
                endDate: '20200101',
            });
        }
        return items;
    }


}