import { Injectable,  } from "react-rxbuilder";
import { VerintJSClient, verintpb } from "@ftdr/verint-js-client";
import {
  ProtobufHttpClient,
  TokenHandlingHttpClient,
} from "@ftdr/http-utils";
import { VerintJSClientMock } from "./mock/verintJsClint.mock";
import axios from "axios";
import { EnvironmentState } from "../models";
import { GetOIDCHub } from "../assets/auth/serviceConfig";
import { OidcUser } from "@ftdr/pkce-js";
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";

@Injectable()
export class VerintCallRecordingService {
    static instance: VerintCallRecordingService;
    private verint?: VerintJSClient | VerintJSClientMock = undefined;
    private corpClient = GetOIDCHub().serviceMap.get("corporate");
    private url: string = '';
    private token: string = '';

    async createInstance(token: string) {
      if(!token){
        this.verint = new VerintJSClientMock();
      }
      else{
        try {
          const { data } = await axios.get<EnvironmentState>(
            "/client-configuration-json"
          );
          this.url = data.verintApiUrl;
          this.token = token;
          const tokenHttpClient = new TokenHandlingHttpClient(token);
          const protoBuffHttpClient = new ProtobufHttpClient(tokenHttpClient);
          this.verint = new VerintJSClient(data.verintApiUrl, protoBuffHttpClient);
        } catch (e) {
          const tokenHttpClient = new TokenHandlingHttpClient(token);
          const protoBuffHttpClient = new ProtobufHttpClient(tokenHttpClient);
          this.verint = new VerintJSClient('https://dev.apis.frontdoorhome.com/', protoBuffHttpClient);
          console.error("Error loading env variables, set default values");
        }
      }
    }

    renewToken() {
      if(this.corpClient){
        this.corpClient.renewToken().then((x: OidcUser) => {
          const tokenHttpClient = new TokenHandlingHttpClient(x.id_token);
          const protoBuffHttpClient = new ProtobufHttpClient(tokenHttpClient);
          this.verint = new VerintJSClient(this.url, protoBuffHttpClient);
        }).catch(() => {
          window.location.replace("/logout");
        });
      }
    }

    searchVerintArchive(requestData: verintpb.ISearchRequest) {
      if(!!this.verint){
        var promise = this.verint.getSearchArchive(requestData);
        promise.catch(e => {
          this.renewToken();
        })
        return promise;
      }
      return null;
    }

    scheduleVerintArchiveDownload(requestData: verintpb.IArchiveDownloadRequest) {
      if(!!this.verint){
        let promise = this.verint.postScheduleArchiveDownload(requestData);promise.catch(e => {
          this.renewToken();
        })
        return promise;
      }
      return null;
    }

    postVerifyArchiveDownload(requestData: verintpb.IMediaDownloadVerificationRequest) {
      if(!!this.verint){
        let promise = this.verint.postVerifyArchiveDownload(requestData);promise.catch(e => {
          this.renewToken();
        })
        return promise;
      }
      return null;
    }

    async downloadFile(token: string, url: string, filename?: string) {
      try {
        
        let myHeaders = {
          "Authorization": `Bearer ${token}`
        };
  
        let res =await fetch(url, 
          {
            method: "GET",
          headers: myHeaders
          });  
        const blob = await res.blob();
        const newBlob = new Blob([blob]);
        saveAs(newBlob, filename || "download.wav");
      }
      catch(e) {
        toast.error(`${e}`);
      }
    }
  }