import React, {
    FC,
  } from "react";
import { Avatar, Input, IconPhone, DateTimeInput, Button, Message } from "@ftdr/blueprint-components-react";
import { SearchFilter } from '../../models';
import { OidcUser } from "@ftdr/pkce-js";

export type SearchFilterProps = {
    filtersApplied: (filters: SearchFilter) => void;
    onClearFilers: () => void;
    user?: OidcUser;
};

type Initials = {
    first: string;
    last: string;
}

export const SearchFilters:FC<SearchFilterProps> = ({filtersApplied, onClearFilers, user}) => {
    const [agentName, setAgentName] = React.useState("");
    const [agentId, setAgentId] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [selectedDate, setSelectedDate] = React.useState<Date|null>(null);
    const [error, displayError] = React.useState(false);
    const phoneRegex = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;

    const validFilters = () => {
        if(agentId.trim() === "" && agentName.trim() === "" && !phoneRegex.test(phone) && selectedDate === null){
            return false;
        }
        return true;
    }

    const getNames = (user?:OidcUser): Initials => {
        let first = "", last = "";
        if(user){
            const names = user.profile.mailnickname.split('.');
            first = names[0];
            last = names[1]
        }
        return {first, last};
    }

    const { first, last } = getNames(user);

    return (<div className="filter-container">
        <Input
            formField
            label="Agent Name"
            value={agentName}
            onChange={(e:any) => {
                if(e && e.target){
                    setAgentName(e.target.value);
                }
                displayError(false);
            }}
            showClearButton
        />
        <Input
            formField
            label="Agent ID"
            value={agentId}
            onChange={(e:any) => {
                if(e && e.target){
                    setAgentId(e.target.value);
                }
                displayError(false);
            }}
            showClearButton
        />
        <Input
            formField
            label="Primary Phone"
            placeholder="(000) 000-0000"
            mask="(000) 000-0000"
            value={phone}
            onChange={(e:any) => {
                if(e && e.target){
                    setPhone(e.target.value);
                }
                
                displayError(false);
            }}
            startEnhancer={<IconPhone color="gray" />}
        />
        <DateTimeInput
            label="Creation Date"
            selectedDate={selectedDate}
            onDateSelect={(date:Date|null) => {
                setSelectedDate(date);
                displayError(false);
            }}
            showMonths={1}
            onlyDate
            datePicker
            showOutsideMonthDates={true}
            allowSelectOutsideMonthDates
        />
        {error && <Message matchColor className="mb-4" status="error" >
            Please fill at least one of the fields
        </Message>}
        <div className="button-filter-container">
            <Button 
                color="accent" 
                label="Clear" 
                variant="outlined" 
                size="small" 
                className="mx-2" 
                onClick={() => {
                    setPhone("");
                    setAgentName("");
                    setAgentId("");
                    setSelectedDate(null);
                    onClearFilers();
                }}/>
            <Button 
                color="accent" 
                label="Search" 
                variant="filled" 
                size="small" 
                className="mx-2" 
                onClick={()=>{
                    if(!validFilters()){
                        displayError(true);
                    }
                    else{
                        filtersApplied(new SearchFilter(agentName, agentId, phone, selectedDate, user ? user.profile.uid || '' : ''));
                    }
                }}
            />
        </div>
        <Avatar  variant="dark" firstName={first} lastName={last}></Avatar>
    </div>)
}