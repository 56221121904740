export const enTextTemplates = {
  BDS_MESSAGE_ERROR: "Error",
  BDS_MESSAGE_INFO: "Info",
  /* Payment Method Errors */
  STRIPE_LOAD_ERROR: "Stripe is not Loaded on page",
  STRIPE_UNKNOWN_ERROR: "Unknown Error",
  STRIPE_INTENT_ERROR: "There is a problem with set payment method",
  NETWORK_ERROR: "Network error",
  STRIPE_REQUIRED_FILED_ERROR: "This field is required",
  STRIPE_VALIDATION_ERROR: "This field is not valid",

  ACH_ROUTING_NUMBER_ERROR: "Routing number in not valid",
  ACH_REQUIRED_FILED_ERROR: "This field is required",
  ACH_CONFIRM_FILED_ERROR: "Account Numbers do not match",
  ACH_ROUTING_NUMBER_LENGTH_ERROR: "Routing number should have 9 characters",

  ACCOUNT_NUMBER_LABEL: "Account Number",
  ACCOUNT_NUMBER_CONFIRMATION_LABEL: "Confirm Account Number",
  ROUTING_NUMBER_LABEL: "Routing Number",

  ERROR_STATUS_CODE_400:
    "Payment Method not supported by payment-method-coordinator",
  ERROR_STATUS_CODE_401: "Not authorized: Request failed with status code 401",
  ERROR_STATUS_CODE_403:
    "Missing permissions: Request failed with status code 401",
  ERROR_STATUS_CODE_404: "Not found: Request failed with status code 404",
  ERROR_STATUS_CODE_409: "Request failed with status code 409",
  ERROR_STATUS_CODE_503: "Request failed with status code 503",
};
