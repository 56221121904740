import { AuthProvider } from '@ftdr/use-auth'
import "@ftdr/blueprint-core/themes/ahs-web-app.css";
import "./assets/verint-search-portal.css"
import "react-toastify/dist/ReactToastify.css";
import { App } from "@ftdr/blueprint-components-react";
import { enTextTemplates } from "./assets/i18n/en-text-templates";
import { Login, Logout, Callback, SearchView, VerifyDownload } from './components';
import { serviceConfigs } from "./assets/auth/serviceConfig";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

function VerintPortalApp() {
  return (
    <AuthProvider serviceConfigs={serviceConfigs}>
      <App
      className="payment-method-micro-frontend"
        appSettings={{
          language: "en_US",
          textTemplatesByLanguage: {
            en_US: enTextTemplates,
          },
          custom: {
            test: true
          },
          showHeader: true
        }}
      >
        <Router>
          <div className="main-container">
            <Switch>
              <Route exact path="/">
                <SearchView></SearchView>
              </Route>
              <Route path="/login">
                <Login></Login>
              </Route>
              <Route path="/logout">
                <Logout></Logout>
              </Route>
              <Route exact path="/verify" component={VerifyDownload} />
              <Route exact path="/oauth2/callback" component={Callback} />
            </Switch>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            draggable
            pauseOnHover
          />
        </Router>
      </App>
    </AuthProvider>
  );
}

export default VerintPortalApp;
