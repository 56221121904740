import React, { useEffect } from 'react';
import { GetOIDCHub } from "../../assets/auth/serviceConfig";
import { toast } from "react-toastify";

export const Login = () => {
  const corpClient = GetOIDCHub().serviceMap.get("corporate")

  useEffect(() => {
    if (corpClient) {
        let key = getQueryVariable('callbackToken');
        corpClient.getUser().then(u => {
            if (u?.id_token) {
                if(key) {
                    window.location.replace(`/verify?mkey=${key}`);
                }
                else {
                    window.location.replace("/");
                }
            }
            else {
                corpClient.login().catch(e => {
                    alert(`failed to login - ${e}`)
                });
            }
        }).catch(e => {
            toast.error(`Error getting user information`);
        })
    }
  });

    const getQueryVariable = function(variable:string)
    {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){
                    return pair[1];
                }
            }
            return(false);
    }

  return (<div>
      Authenticating...
  </div>);
}