import { verintpb, common } from "@ftdr/verint-js-client";
export class SearchFilter {
    agentName: string;
    agentId: string;
    phoneNumber: string;
    creationDate?: Date|null;
    userId: string;

    constructor(agentName: string, agentId: string, phone: string, date: Date|null, userId:string) {
        this.agentName = agentName;
        this.agentId = agentId;
        this.creationDate = date;
        this.phoneNumber = phone;
        this.userId = userId;
    }

    toRequestData(): verintpb.ISearchRequest {
        this.phoneNumber = this.phoneNumber.replace(/\D/g,"");;
        return {
            queryByAgentName: this.agentName,
            queryByAgentId: this.agentId,
            queryByCustomerPhoneNumber: this.phoneNumber,
            queryByDate: this.creationDate ? this.creationDate.toISOString().split('T')[0]  : '',
            userId: this.userId,
            userRole: common.UserRole.DEVELOPER,
            tenantId: common.Tenant.AHS
        };
    }

    equal(b: SearchFilter):boolean {
        if(this.agentName === b.agentName && this.agentId === b.agentId && this.phoneNumber === b.phoneNumber && this.creationDate === b.creationDate) {
            return true;
        }
        return false;
    }

    isEmpty() {
        return this.agentName === '' && this.agentId === '' && this.phoneNumber === '' && !this.creationDate;
    }
};

