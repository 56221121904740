import { VerintCallRecordingService } from "../services/verintCallRecordingSvc";
import React, { useEffect, useState } from 'react';
import { GetOIDCHub } from "../assets/auth/serviceConfig";
import { common, verintpb } from "@ftdr/verint-js-client";
import { OidcUser } from "@ftdr/pkce-js";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

export const VerifyDownload:React.FC = () => {
    const corpClient = GetOIDCHub().serviceMap.get("corporate")
    const [usr, setUser] = useState<OidcUser>();

    useEffect(() => {
        if (corpClient) {
          corpClient.renewToken().catch(() => {
            let key = getQueryVariable('mkey');
            window.location.replace(`/logout?callbackToken=${key}`);
          })
          corpClient.getUser().then(async (u) => {
            let key = getQueryVariable('mkey');
              if (u?.id_token && !usr) {
                let svc = new VerintCallRecordingService();
                await svc.createInstance(u?.id_token);
                svc.postVerifyArchiveDownload({
                    mailKey: key || '',
                    userRole: common.UserRole.DEVELOPER,
                    tenantId: common.Tenant.AHS
                })?.then((x: AxiosResponse<verintpb.IMediaDownloadVerificationResponse>) => {
                    if(x.status === 200 && x.data.status === common.Status.SUCCESS) {
                        svc.downloadFile(u.id_token, x.data.downloadLink || '', x.data.fileName || undefined);
                    }
                }).catch(e => {
                  toast.error(`${e}`);
                })
                setUser(u);
              }
              else if(!usr){
                window.location.replace(`/login?callbackToken=${key}`);
              }
          });
        }
      });

    
    const getQueryVariable = function(variable:string)
    {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){
                    return pair[1];
                }
            }
            return(false);
    }

    return (
        <div>
            Download file...
        </div>
    )
}