import {OIDCService, Hub, Config} from "@ftdr/pkce-js";

export const serviceConfigs:Config[] = [
	{
		nickname: "corporate",
		authority: "https://login.microsoftonline.com/050e16e5-2cf7-4ca6-8d10-9a639dbeab71",
		client_id: "fb989081-2563-4a08-8eda-5b61fd71abae",
		debug: true,
		metadata_uri: "https://login.microsoftonline.com/050e16e5-2cf7-4ca6-8d10-9a639dbeab71/v2.0/.well-known/openid-configuration",
		post_logout_redirect_uri: window.location.origin+"/",
		redirect_uri: window.location.origin+"/",
		scope: "openid email profile"
	},
	// ... as many configs as you like
]

export function GetOIDCHub(): Hub {
    return new Hub([
		new OIDCService({
			nickname: "corporate",
			authority: "https://login.microsoftonline.com/050e16e5-2cf7-4ca6-8d10-9a639dbeab71",
			client_id: "fb989081-2563-4a08-8eda-5b61fd71abae",
			debug: false,
			metadata_uri: "https://login.microsoftonline.com/050e16e5-2cf7-4ca6-8d10-9a639dbeab71/v2.0/.well-known/openid-configuration",
			post_logout_redirect_uri: window.location.origin+"/login",
			redirect_uri: window.location.origin+"/oauth2/callback",
			scope: "openid email profile"
		}),
	])	
}