import { Component} from "react";
import { VerintCallRecordingService } from "../../services/verintCallRecordingSvc";
import { SearchFilter, SearchResultRawData } from '../../models';
import { common, verintpb } from "@ftdr/verint-js-client";
import { OidcUser } from "@ftdr/pkce-js";
import { toast } from "react-toastify";

import { Button, Table, IconRefreshDouble, IconDownload, Message, TableColumn, Text, Tag, Link } from "@ftdr/blueprint-components-react";
import { AxiosResponse } from "axios";

export type SearchResultProps = {
  filters?: SearchFilter;
  user?: OidcUser;
};

export type SearchResultState = {
  data: SearchResultRawData[];
  enableDownload: boolean;
  user?: OidcUser|null;
  noDataFound: boolean;
};

export class SearchResults extends Component<SearchResultProps, SearchResultState> {
  filters?: SearchFilter;
  svc: VerintCallRecordingService;
  columns: TableColumn<SearchResultRawData>[];
  selectedItems: verintpb.IDownloadRequestEntry[];
  
  constructor(props: SearchResultProps) {
    super(props);
    const { filters } = props;
    this.filters = filters;
    this.svc = new VerintCallRecordingService();
    this.svc.createInstance('');
    this.columns = this.getColumns();
    this.selectedItems = [];
    this.executeDownload = this.executeDownload.bind(this);
    this.selectItems = this.selectItems.bind(this);
    this.state = {
      data: [],
      enableDownload: false,
      user: null,
      noDataFound: false
    };
  }
  
  formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  componentDidUpdate(prevProps:SearchResultProps, prevState:SearchResultState, snapshot:any) {
    const { user } = this.props;
    if(prevProps.user?.id_token !== this.props.user?.id_token){
      this.setState({
        user: this.props.user
      });
      this.svc.createInstance(user?.id_token || '');
    }
    if((this.state.data.length === 0 && this.props.filters && !this.props.filters.isEmpty()) || (prevProps.filters &&  this.props.filters && !prevProps.filters?.equal(this.props.filters))) {
      this.svc.searchVerintArchive(this.props.filters.toRequestData())?.then((x: AxiosResponse<verintpb.ISearchResponse>)=> {
        if(x.status === 200 && x.data.status === common.Status.SUCCESS) {
          if(this.state.data.length === 0 && x.data.results?.length === 0){
            this.setState({
              noDataFound: true
            });
            return;
          }
          this.setState({
            data: x.data.results?.map(y => {
              return {
                indexId: y.indexId,
                agentName: y.agentName,
                agentId: y.agentId,
                organization: y.organization,
                phoneNumber: this.formatPhoneNumber(y.phoneNumber || ''),
                description: y.description,
                file: {
                  fileName: y.fileName,
                  fileExtension: y.fileExtension,
                  fileSize: y.fileSize,
                  fileType: y.fileType
                },
                startDate: y.startDate === '0001-01-01' ? '' : y.startDate,
                endDate: y.endDate === '0001-01-01' ? '' : y.endDate
              };
            }) || [],
            noDataFound: x.data.results?.length === 0
          });
        }
      }).catch(e => {
        toast.error(`${e}`);
      });
    } else if(this.state.data.length > 0){
      if((this.props.filters && this.props.filters.isEmpty()) || !this.props.filters){
        this.setState({
          data: []
        });
      }
    }
  }
  
  getStatusTag(status: string) {
    switch(status){
      case 'scheduled':
        return { text: "Download scheduled", icon: IconDownload };
      case 'Downloading':
        return { text: "Scheduling", icon: IconRefreshDouble };
      case '':
      default:
        return { text: "", icon: null };
    }
  }

  getColumns() {
    const columns:TableColumn<SearchResultRawData>[] = [
      {
        Header: <Text variant="heading-06">Agent Name</Text>,
        accessor: "agentName", // accessor is the "key" in the data
        Cell: ({ value }: SearchResultRawData) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: <Text variant="heading-06">Agent Id</Text>,
        accessor: "agentId", // accessor is the "key" in the data
        Cell: ({ value }: SearchResultRawData) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: <Text variant="heading-06">Organization</Text>,
        accessor: "organization",
        Cell: ({ value }: SearchResultRawData) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: <Text variant="heading-06">Phone Number</Text>,
        accessor: "phoneNumber",
        Cell: ({ value }: SearchResultRawData) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: <Text variant="heading-06">Description</Text>,
        accessor: "description",
        Cell: ({ value }: SearchResultRawData) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06" className="normal-case">
            File
          </Text>
        ),
        accessor: "file",
        disableSortBy: true,
        Cell: ({ value }: SearchResultRawData) => (
          <>
            <Text variant="caption">{`${value.fileName}`}</Text>
            <Text variant="caption">{`${value.fileSize} bytes`}</Text>
          </>
        ),
      },
      {
        Header: <Text variant="heading-06">Start Date</Text>,
        accessor: "startDate",
        Cell: ({ value }: SearchResultRawData) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: <Text variant="heading-06">End Date</Text>,
        accessor: "endDate",
        Cell: ({ value }: SearchResultRawData) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: <Text variant="heading-06">Status</Text>,
        accessor: "status",
        Cell: ({ value }: SearchResultRawData) => {
          const tagProps:any = this.getStatusTag(value)
          return (tagProps.text &&
            <div>
              <Tag
                size="small"
                className={value === "Downloading" ? "spin-icon" : ""}
                textClassName="font-bold"
                startIcon={tagProps.icon}
                color={value === "scheduled" ? "success" : "warning"}
                iconColor={value === "scheduled" ? "success" : "currentcolor"}
              >
                {tagProps.text}
              </Tag>
              <Text variant="caption">{value === 'scheduled' ? "You will be notified when it is available" : ""}</Text>
            </div>
          );
        },
      }
    ];
    return columns;
  }
  selectItems(array:any[]) {
    const { enableDownload } = this.state;
    const selected = array.map(x => {
      if(x && x.original){
        return {
          indexId: x.original.indexId,
          fileType: x.original.file.fileType,
          fileExtension: x.original.file.fileExtension
        }
      }
      return {};
    });
    this.selectedItems = selected;
    if(enableDownload !== (this.selectedItems.length > 0)) {
      this.setState({
        enableDownload: this.selectedItems.length > 0
      });
    }
  }
  executeDownload() {
    const{ user } = this.props;

    let loadingData = [...this.state.data];
    this.selectedItems.forEach((item) => {
          const itemToUpdate = loadingData.find(i => i.indexId === item.indexId);
          if(itemToUpdate){
            itemToUpdate.status = "Downloading";
          }
    });
    this.setState({
      data: loadingData
    });
 
    this.svc.scheduleVerintArchiveDownload({
      downloadRequests: this.selectedItems,
      requestedByName: user?.profile.name,
      requestedByEmail: user?.profile.email,
      userRole: common.UserRole.DEVELOPER,
      tenantId: common.Tenant.AHS
    })?.then((x: AxiosResponse<verintpb.IArchiveDownloadResponse>) => {
      if(x.status === 200 && x.data.status === common.Status.SUCCESS) {
        let updatedData = [...this.state.data];
        x.data.results?.forEach((item) => {
          const itemToUpdate = updatedData.find(i => i.indexId === item.indexId);
          if(itemToUpdate){
            itemToUpdate.status = item.status || '';
          }
        })
        this.setState({
          data: updatedData
        });
      }
    }).catch(e => {
      toast.error(`${e}`);
    })
  }
  render() {
    const { columns,selectedItems } = this;
    const { data, noDataFound } = this.state;
    

    return (
      <div className="verint-search-result-container">
        <div className="verint-search-result-bar">
          <div>
            <Button
              endIcon={<IconDownload />}
              size="small"
              variant="outlined"
              color="accent"
              label="Download"
              disabled={selectedItems.length === 0}
              onClick={this.executeDownload}
            />
          </div>
        </div>
        <div className="verint-search-result">
          <Table
            columns={columns}
            data={data}
            striped="gray"
            variant="heavy"
            sortable={false}
            paginate={false}
            selectable={true}
            onRowsSelect={this.selectItems}
            rowFooterRenderer={() => (
              <div className="border border-error-500">
                
              </div>
            )}
            key="search_verint_table"
          />
          {noDataFound && <Message className="p-1 bg-primary-50" status="info" matchColor={true} size="medium">
            No records found.
          </Message>}
        </div>
      </div>);
  }
}